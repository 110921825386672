export const HOME = "/";
export const SIGN_UP = "/signup";
export const FORGETPASS = "/forgot-pass";
export const DASHBOARD = "/dashboard";
export const SETTING = "/setting";
export const CARDS = "/cards";
// company
export const COMPANIES = "/companies";
export const COMPANY_DETAIL = "/company-detail/:companyId";
export const COMPANY_DETAIL_VIEW_MORE = "/company-detail/view-more/:companyId";
export const CR_INFO = "/cr-info/:companyId";
export const TRANSACTION_DETAIL = "/companies/transaction-detail/:tranxId";
export const CARD_DETAIL = "/companies/card-detail/:cardId";
export const EMPLOYEE_DETAIL = "/companies/employee-detail/:employeeId";
export const MANAGE_EMPLOYEE = "/manage-employee";
export const SELECT_PLAN = "/select-plan";
export const MANAGE_PLAN = "/manage-plan";
export const USERS = "/users";

// plans
export const PLANS = "/plans";
export const CREATE_PLAN = "/create-plan";
export const EDIT_PLAN = "/edit-plan";
export const ACCEPT_INVITATION = "/accept-invite";
