import { createAction } from "redux-actions";

export const INIT_SIGN_IN = "INIT_SIGN_IN";
export const initSIgnIn = createAction(INIT_SIGN_IN);

export const LOGIN = "LOGIN";
export const login = createAction(LOGIN);

export const REGISTER = "REGISTER";
export const register = createAction(REGISTER);

export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const verifyEmail = createAction(VERIFY_EMAIL);

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const forgotPassword = createAction(FORGOT_PASSWORD);

export const RESET_PASSWORD = "RESET_PASSWORD";
export const resetPassword = createAction(RESET_PASSWORD);

export const SET_AUTHENTICATION_TOKEN = "SET_AUTHENTICATION_TOKEN";
export const setAuthenticationToken = createAction(SET_AUTHENTICATION_TOKEN);

export const GET_USER_PREFERENCE = "GET_USER_PREFERENCE";
export const getUserPreference = createAction(GET_USER_PREFERENCE);

export const UPDATE_USER_PREFERENCE = "UPDATE_USER_PREFERENCE";
export const updateUserPreference = createAction(UPDATE_USER_PREFERENCE);

export const GET_SITE_PREFERENCE = "GET_SITE_PREFERENCE";
export const getSitePreference = createAction(GET_SITE_PREFERENCE);

export const UPDATE_SITE_PREFERENCE = "UPDATE_SITE_PREFERENCE";
export const updateSitePreference = createAction(UPDATE_SITE_PREFERENCE);

export const UPLOAD_PROFILE_PICTURE = "UPLOAD_PROFILE_PICTURE";
export const uploadProfilePicture = createAction(UPLOAD_PROFILE_PICTURE);

export const GET_PROFILE_PICTURE = "GET_PROFILE_PICTURE";
export const getProfilePicture = createAction(GET_PROFILE_PICTURE);

export const GET_USER_LIST = "GET_USER_LIST";
export const getUserList = createAction(GET_USER_LIST);

export const UPDATE_ADMIN_USER = "UPDATE_ADMIN_USER";
export const updateAdminUser = createAction(UPDATE_ADMIN_USER);

export const CREATE_ADMIN_USER = "CREATE_ADMIN_USER";
export const createAdminUser = createAction(CREATE_ADMIN_USER);

export const DELETE_ADMIN_USER = "DELETE_ADMIN_USER";
export const deleteAdminUser = createAction(DELETE_ADMIN_USER);

export const ACCEPT_INVITATION = "ACCEPT_INVITATION";
export const acceptInvitation = createAction(ACCEPT_INVITATION);

export const LOGOUT = "LOGOUT";
export const logout = createAction(LOGOUT);
